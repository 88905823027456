import React from 'react';
import Link from 'gatsby-link';

import allianz from '../assets/images/logos/allianz.svg';
import national_trust from '../assets/images/logos/national-trust.svg';
import rics from '../assets/images/logos/RICS.svg';
import bristolfc from '../assets/images/logos/bristol-city.svg';

const ClientsBanner = () => (
  <div id="clients-banner">
    <div className="inner">
      <span className="heading">Past clients include:</span>
      <div className="logos">
        <ul>
          <li>
            <img src={allianz} alt="Allianz Insurance" />
          </li>
          <li>
            <img src={national_trust} alt="The National Trust" />
          </li>
          <li>
            <img src={bristolfc} alt="Bristol City FC" />
          </li>
          <li>
            <img src={rics} alt="RICS" />
          </li>
        </ul>
      </div>
    </div>
    <div className="inner">
      <span className="heading">
        <h2>Live band hire, Bristol</h2>
      </span>
      <div>
        <p>
          When looking to hire a band you want to be sure you can book with confidence. As a wedding
          band with over 10 years experience in the industry you can be sure you are booking a band
          that will deliver!
        </p>
        <p>
          <Link to="/reviews"> See all our latest reviews here.</Link>
        </p>
      </div>
    </div>
    <div className="inner">
      <span className="heading">
        <h2>Fully certified and insured</h2>
      </span>
      <div>
        <p>
          With over £1 million of public liability insurance and a fully PAT tested rig, you can be
          confident all bases are covered. As a function band that has performed for several major
          brands in Bristol and beyond we are experienced in providing all the necessities required
          by venues.
        </p>
      </div>
    </div>
  </div>
);

export default ClientsBanner;
