import React from 'react';
import Link from 'gatsby-link';

import av_video from '../assets/video/audio_vultures.mp4';

const Video = () => (
  <Link to="/media#video">
    <div className="fullscreen-bg">
      <video
        loop
        muted
        autoPlay
        // poster={audio_vultures_function_band_bristol_thumb}
        className="fullscreen-bg__video"
        playsInline>
        <source src={av_video} type="video/mp4" />
      </video>
    </div>
  </Link>
);

export default Video;
