import React from 'react';
import AmbsaHero from 'ambsa-hero';

import image from '../assets/images/media/audio_vultures_party_band.jpg';

const Hero = () => (
  <AmbsaHero image={image} stick="center" brightness="darker">
    <div>
      <h1 className="ambsa-hero-display">
        <span>Bristol's Premier Wedding Band</span>
      </h1>
      <p className="ambsa-hero-lead">
        Audio Vultures are a premier wedding band from Bristol, who are dedicated to creating an
        electric and energetic atmosphere to fill the dance floor, whatever the venue!
      </p>
      <p className="ambsa-hero-lead">
        With their authentic renditions of chart hits from the last 50 years, and many classics
        thrown in for good measure, they won&apos;t be happy until they have the venue bouncing and
        the crowd singing along with them!
      </p>
      <p>
        Having performed for many well known and high profile clients, including The National Trust,
        and Bristol City FC, and packing over ten years of experience, the band have everything you
        need for an energetic evening to remember!
      </p>

      <p className="ambsa-hero-lead">
        Covering Bristol, Bath and nationwide Audio Vultures are the first choice wedding band in
        Somerset and beyond! <a href="#contact">Get in touch today</a> for a no obligation quote.
      </p>
    </div>
  </AmbsaHero>
);

export default Hero;
