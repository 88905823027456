import React from 'react';
import Link from 'gatsby-link';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactPlayer from 'react-player';

import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import DJService from '../components/DJService';
import AcousticBanner from '../components/AcousticBanner';
import ClientsBanner from '../components/ClientsBanner';
import Hero from '../components/Hero';
import Video from '../components/Video';
import InstagramHome from '../components/Instagram-home';
import audio_vultures_covers_band_thumb from '../assets/images/media/audio_vultures_covers_band_thumb.jpg';
import audio_vultures_party_band_thumb from '../assets/images/media/audio_vultures_party_band_thumb.jpg';
import audio_vultures_function_band_bristol_thumb from '../assets/images/media/audio_vultures_function_band_bristol_thumb.jpg';
import share_image from '../assets/images/audio-vultures-share.jpg';

class HomeIndex extends React.Component {
  render() {
    // const siteTitle = this.props.data.site.siteMetadata.title;
    return (
      <Layout>
        <div>
          <Helmet>
            <title>Wedding Band Bristol, Band for Hire Bath – Audio Vultures</title>
            <meta
              name="description"
              content="Audio Vultures are a wedding band in Bristol, providing party and wedding band services in Bristol, Bath and nationwide. Get a no obligation quote today!"
            />
            <meta name="image" content={share_image} />
            <link rel="canonical" href={'https://www.audiovultures.co.uk'} />
          </Helmet>

          <div id="main" className="homepage-main">
            <div className="inner video-inner">
              <div id="video-homepage" className="12u 12u$(medium)">
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://youtu.be/rfdDUb1XIaM"
                    width="100%"
                    height="100%"
                  />
                </div>
                <br />
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://youtu.be/_cs6U8k0ob8"
                    width="100%"
                    height="100%"
                  />
                </div>{' '}
              </div>
            </div>
            <Video />
            <Hero />
            <ClientsBanner />
            <section className="audioplayer">
              <div className="8u -2u 10u$(small)" style={{ margin: '0 auto' }}>
                <iframe
                  width="100%"
                  height="390"
                  scrolling="no"
                  frameBorder="no"
                  title="Audio Vultures Party Band"
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/983807023&color=%23ff1919&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                />
              </div>
            </section>
            <section className="homepage-images">
              <Img
                fluid={this.props.data.imageOne.childImageSharp.fluid}
                alt="Audio Vultures Party Band"
                style={{ width: '50%', backgroundSize: 'cover', display: 'inline-block' }}
              />
              <Img
                fluid={this.props.data.imageTwo.childImageSharp.fluid}
                alt="Audio Vultures Wedding Band Bristol"
                style={{ width: '50%', backgroundSize: 'cover', display: 'inline-block' }}
              />
            </section>
            <InstagramHome />
            <section id="one" className="tiles">
              <article
                style={{
                  backgroundImage: `url(${audio_vultures_function_band_bristol_thumb})`,
                }}>
                <header className="major">
                  <h3>Reviews</h3>
                  <p>Read our reviews</p>
                </header>
                <Link to="/reviews" className="link primary" />
              </article>
              <article
                style={{
                  backgroundImage: `url(${audio_vultures_party_band_thumb})`,
                }}>
                <header className="major">
                  <h3>Song List</h3>
                  <p>View our full set list</p>
                </header>
                <Link to="/songs" className="link primary" />
              </article>
              <article
                style={{
                  backgroundImage: `url(${audio_vultures_covers_band_thumb})`,
                }}>
                <header className="major">
                  <h3>Media</h3>
                  <p>View our photos and videos</p>
                </header>
                <Link to="/media" className="link primary" />
              </article>
              <article
                style={{
                  backgroundImage: `url(${audio_vultures_function_band_bristol_thumb})`,
                }}>
                <header className="major">
                  <h3>FAQ</h3>
                  <p>Read answers to frequently asked questions</p>
                </header>
                <Link to="/frequently-asked-questions" className="link primary" />
              </article>
            </section>
            <br /> <br /> <br /> <br />
            <AcousticBanner />
            <DJService />
          </div>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "media/audio_vultures_covers_band_thumb.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "media/audio_vultures_party_band_thumb.jpg" }) {
      ...fluidImage
    }
  }
`;
