import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const InstagramHome = () => {
  const data = useStaticQuery(graphql`
    query {
      InstaOne: file(relativePath: { eq: "instagram/insta1.jpg" }) {
        ...fluidImage
      }
      InstaTwo: file(relativePath: { eq: "instagram/insta2.jpg" }) {
        ...fluidImage
      }
      InstaThree: file(relativePath: { eq: "instagram/insta3.jpg" }) {
        ...fluidImage
      }
      InstaFour: file(relativePath: { eq: "instagram/insta4.jpg" }) {
        ...fluidImage
      }
    }
  `);

  return (
    <section className="instagram-images insta-home">
      <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
        <Img
          fluid={data.InstaOne.childImageSharp.fluid}
          alt="Audio Vultures Covers Band Bristol"
          style={{ width: '25%', backgroundSize: 'cover', display: 'inline-block' }}
        />
      </a>
      <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
        <Img
          fluid={data.InstaTwo.childImageSharp.fluid}
          style={{ width: '25%', backgroundSize: 'cover', display: 'inline-block' }}
          alt="Audio Vultures Wedding Band Bristol"
        />
      </a>
      <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
        <Img
          fluid={data.InstaThree.childImageSharp.fluid}
          alt="Audio Vultures Wedding Band Bristol"
          style={{ width: '25%', backgroundSize: 'cover', display: 'inline-block' }}
        />
      </a>
      <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
        <Img
          fluid={data.InstaFour.childImageSharp.fluid}
          alt="Audio Vultures Function Band Bristol"
          style={{ width: '25%', backgroundSize: 'cover', display: 'inline-block' }}
        />
      </a>
    </section>
  );
};

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default InstagramHome;
